@font-face {
  font-family: graphik;
  src: url(../fonts/graphik_medium.woff);
  font-weight: bold;
}

@font-face {
  font-family: graphik;
  src: url(../fonts/graphik_regular.woff);
}

html,
body{
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: graphik;
}